import { CHAIN_INFO_LIST, CHAIN_IS_COSMOS_SDK_v50, STRIDE_CHAIN_INFO } from '@/config'
import { useDexWallet, useSelectedWallet, useStrideWallet } from '@/contexts/wallet'
import { queryKeys } from '@/query-keys'
import { fatal } from '@/utils'
import {
  Account,
  TxMetaData,
  TxQueryResponse,
  isDymMsgLiquidStake,
  isMsgLiquidStake,
  isMsgLsmLiquidStake,
  isMsgTransfer,
  isSafeModeAccount,
  isTiaMsgLiquidStake
} from '@/wallet-utils'
import axios, { AxiosError } from 'axios'
import { isSameDay } from 'date-fns'
import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'
import { createIbcDexMetaData } from './create-ibc-dex-meta-data'
import { createIbcLiquidStakeAutopilotMetaData } from './create-ibc-liquid-stake-autopilot-meta-data'
import { createIbcMetaData } from './create-ibc-meta-data'
import { createIbcWithdrawStTokenMetaData } from './create-ibc-withdraw-st-token-meta-data'
import { createLiquidStakeMetaData } from './create-liquid-stake-meta-data'
import { createLsmLiquidStakeMetaData } from './create-lsm-liquid-stake-meta-data'
import { createRedemptionMetaData } from './create-redemption-meta-data'
import { handleIbc } from './handle-ibc'
import { handleIbcDex } from './handle-ibc-dex'
import { handleIbcLiquidStakeAutopilot } from './handle-ibc-liquid-stake-autopilot'
import { handleIbcWithdrawStToken } from './handle-ibc-withdraw-st-token'
import { handleLiquidStake } from './handle-liquid-stake'
import { handleLsmLiquidStake } from './handle-lsm-liquid-stake'
import { redemptionRecordResponseSchema, unbondingsResponseSchema } from './types'
import { createTiaRedemptionMetaData } from './create-tia-redemption-record-meta-data'
import { createTiaLiquidStakeMetaData } from './create-tia-liquid-stake-meta-data'
import { handleTiaLiquidStake } from './handle-tia-liquid-stake-meta-data'
import { createDymRedemptionMetaData } from './create-dym-redemption-record-meta-data'
import { createDymLiquidStakeMetaData } from './create-dym-liquid-stake-meta-data'
import { handleDymLiquidStake } from './handle-dym-liquid-stake-meta-data'
import { HostZoneResponse } from '@/queries'

const useTransactionHistoryQuery = () => {
  const selectedAccount = useSelectedWallet()

  const strideAccount = useStrideWallet()

  const dexAccount = useDexWallet()

  const handleQueryRedemptionRecords = async () => {
    if (!strideAccount || !selectedAccount) {
      throw fatal('Unable to query unbondings while wallet is disconnected')
    }

    const instance = axios.create({
      baseURL: STRIDE_CHAIN_INFO.rest
    })

    const response = await instance.get(
      // In v17 Stride upgrade, an unbonding was assigned to their chain address.
      // However, due to migrations, we still need to query both.
      `Stride-Labs/stride/stakeibc/unbondings/${selectedAccount.address},${strideAccount.address}`
    )

    const unbondings = unbondingsResponseSchema.parse(response.data)

    return unbondings.address_unbondings
      .filter((unbonding) => {
        // Since we also query the Stride address, we need to filter out irrelevant unbondings
        return unbonding.denom === selectedAccount.currency.coinMinimalDenom
      })
      .map((unbonding) => {
        return createRedemptionMetaData({
          unbonding,
          selectedAccount
        })
      })
  }

  const handleQueryTiaRedemptionRecords = async () => {
    if (!strideAccount || !selectedAccount) {
      throw fatal('Unable to query unbondings for TIA while wallet is disconnected')
    }

    const instance = axios.create({
      baseURL: STRIDE_CHAIN_INFO.rest
    })

    const response = await instance.get(
      `Stride-Labs/stride/staketia/redemption_records?address=${strideAccount.address}`
    )

    const data = redemptionRecordResponseSchema.parse(response.data)

    return data.redemption_record_responses.map((payload) => {
      return createTiaRedemptionMetaData({
        payload,
        selectedAccount,
        strideAccount
      })
    })
  }

  const handleQueryDymRedemptionRecords = async () => {
    if (!strideAccount || !selectedAccount) {
      throw fatal('Unable to query unbondings for DYM while wallet is disconnected')
    }

    const instance = axios.create({
      baseURL: STRIDE_CHAIN_INFO.rest
    })

    const response = await instance.get(
      `Stride-Labs/stride/stakedym/redemption_records?address=${strideAccount.address}`
    )

    const data = redemptionRecordResponseSchema.parse(response.data)

    return data.redemption_record_responses.map((payload) => {
      return createDymRedemptionMetaData({
        payload,
        selectedAccount,
        strideAccount
      })
    })
  }

  const handleFetchAddressTransactions = async (account: Account): Promise<TxMetaData[]> => {
    if (!selectedAccount || !strideAccount) {
      throw fatal('Unable to query pending transactions while disconnected.')
    }

    let pageKey = null

    let txs: TxMetaData[] = []

    const now = new Date()

    const chainInfo = CHAIN_INFO_LIST[account.currency.coinDenom]

    const instance = axios.create({
      baseURL: chainInfo.rest
    })

    while (true) {
      const params = new URLSearchParams()
      // @TODO: A huge and obvious part of this function is that we fetch all transactions by going through all of the pages.
      // We loop and fetch the next page by checking the pagination.next_key. The problem lies in the backend, for some reason
      // on the cosmos side, pagination.next_key is always null despite supposedly having more pages. You can test this by
      // querying txs of an address with a lot of transactions. Set pagination.limit, and you'll find that next_key is always
      // null.
      params.append('pagination.key', `${pageKey}`)
      // Our edge server (hosted on Vercel) is not able to keep up with the transactions endpoint
      // which may return a payload (30-60mb) beyond the capacity (>= 4.5 MB) causing the request
      // to fail with 413. From my testing, 10 is still too heavy; 5 seems like a good number.
      params.append('pagination.limit', '5')
      params.append('pagination.reverse', 'true')
      // Descending https://github.com/Stride-Labs/stride/blob/f7ced7ae5cf769c1bd2799f6038a03031fee7015/third_party/proto/cosmos/tx/v1beta1/service.proto#L56
      params.append('order_by', '2')
      // Cosmos v50 SDK deprecates the events parameters in favor of query
      params.append(
        CHAIN_IS_COSMOS_SDK_v50[account.currency.coinDenom] ? 'query' : 'events',
        `message.sender='${account.address}'`
      )

      const response = await instance.get<TxQueryResponse>(`cosmos/tx/v1beta1/txs?${params}`).catch((e) => {
        // If the user has no transactions, we'll get a 500 error from the API. In this case,
        // we still want to allow the user to see the rest of their history.
        // We'll use safeParse here so we're still able to original error if it so happens
        // that the error is not related to the user having no transactions.
        if (isAxiosError(e) && fatalResponseSchema.safeParse(e.response?.data).success) {
          return null
        }

        throw e
      })

      // If it's null, it means that responsem matches `fatalResponseSchema`;
      // meaning that the user has no recent transactions.
      if (response == null) {
        return txs
      }

      // There are no more items
      if (response.data.tx_responses.length === 0) {
        return txs
      }

      for (const res of response.data.tx_responses) {
        // It's outside of our query scope
        if (!isSameDay(new Date(res.timestamp), now)) {
          return txs
        }

        const message = res.tx?.body?.messages[0]

        if (isSafeModeAccount(selectedAccount) || isSafeModeAccount(strideAccount) || isSafeModeAccount(dexAccount)) {
          throw fatal('Safe mode is enabled.')
        }

        if (
          isMsgLiquidStake(message) &&
          handleLiquidStake({
            response: res,
            message,
            strideAccount,
            selectedAccount,
            dexAccount
          })
        ) {
          txs.push(
            createLiquidStakeMetaData({
              response: res,
              message,
              strideAccount,
              selectedAccount,
              dexAccount
            })
          )
        } else if (
          isTiaMsgLiquidStake(message) &&
          handleTiaLiquidStake({
            response: res,
            message,
            strideAccount,
            selectedAccount,
            dexAccount
          })
        ) {
          txs.push(
            createTiaLiquidStakeMetaData({
              response: res,
              message,
              strideAccount,
              selectedAccount,
              dexAccount
            })
          )
        } else if (
          isDymMsgLiquidStake(message) &&
          handleDymLiquidStake({
            response: res,
            message,
            strideAccount,
            selectedAccount,
            dexAccount
          })
        ) {
          txs.push(
            createDymLiquidStakeMetaData({
              response: res,
              message,
              strideAccount,
              selectedAccount,
              dexAccount
            })
          )
        } else if (
          isMsgTransfer(message) &&
          handleIbcLiquidStakeAutopilot({
            response: res,
            message,
            strideAccount,
            selectedAccount,
            dexAccount
          })
        ) {
          txs.push(
            createIbcLiquidStakeAutopilotMetaData({
              response: res,
              message,
              strideAccount,
              selectedAccount,
              dexAccount
            })
          )
        } else if (
          isMsgTransfer(message) &&
          handleIbc({
            response: res,
            message,
            strideAccount,
            selectedAccount,
            dexAccount
          })
        ) {
          txs.push(
            createIbcMetaData({
              response: res,
              message,
              strideAccount,
              selectedAccount,
              dexAccount
            })
          )
        } else if (
          isMsgTransfer(message) &&
          handleIbcDex({
            response: res,
            message,
            strideAccount,
            selectedAccount,
            dexAccount
          })
        ) {
          txs.push(
            createIbcDexMetaData({
              response: res,
              message,
              strideAccount,
              selectedAccount,
              dexAccount
            })
          )
        } else if (
          isMsgTransfer(message) &&
          handleIbcWithdrawStToken({
            response: res,
            message,
            strideAccount,
            selectedAccount,
            dexAccount
          })
        ) {
          txs.push(
            createIbcWithdrawStTokenMetaData({
              response: res,
              message,
              strideAccount,
              selectedAccount,
              dexAccount
            })
          )
        } else if (
          isMsgLsmLiquidStake(message) &&
          handleLsmLiquidStake({ response: res, message, strideAccount, selectedAccount, dexAccount })
        ) {
          txs.push(
            createLsmLiquidStakeMetaData({
              response: res,
              message,
              strideAccount,
              selectedAccount,
              dexAccount
            })
          )
        }
      }

      // There is no next page
      if (!(pageKey = response.data.pagination?.next_key)) {
        return txs
      }
    }
  }

  const handleQueryPendingTransactions = async () => {
    if (!strideAccount || !selectedAccount) {
      throw fatal('Unable to fetch pending transactions while disconnected.')
    }

    if (isSafeModeAccount(selectedAccount) || isSafeModeAccount(strideAccount)) throw fatal('Safe mode is enabled.')

    const getRedemptionsEnabled = async () => {
      if (selectedAccount.currency.coinDenom !== 'TIA') {
        return true
      }

      const instance = axios.create({
        baseURL: STRIDE_CHAIN_INFO.rest
      })

      const response = await instance.get<HostZoneResponse>(`Stride-Labs/stride/stakeibc/host_zone/celestia`)

      const data = partialHostZoneSchema.parse(response.data)

      return data.host_zone.redemptions_enabled
    }

    const redemptionEnabled = await getRedemptionsEnabled()

    const txs: Array<TxMetaData[]> = await Promise.all([
      selectedAccount.currency.coinDenom === 'TIA' && !redemptionEnabled
        ? handleQueryTiaRedemptionRecords()
        : selectedAccount.currency.coinDenom === 'DYM'
        ? handleQueryDymRedemptionRecords()
        : handleQueryRedemptionRecords(),
      handleFetchAddressTransactions(selectedAccount),
      handleFetchAddressTransactions(strideAccount)
    ])

    return { transactions: txs.flat() }
  }

  const addresses = {
    strideAccount: strideAccount?.address ?? '',
    selectedAccount: selectedAccount?.address ?? '',
    // @TODO: Not sure if it makes sense for this to be here given that
    // this is essentially binded to selectedAccount, and will likely change
    // if selectedAccount changes
    dexAccount: dexAccount?.address ?? ''
  }

  return useQuery({
    queryKey: queryKeys.transactionHistoryByAddresses({ addresses }),
    queryFn: handleQueryPendingTransactions,
    enabled: Boolean(strideAccount && selectedAccount),
    staleTime: 60_000
  })
}

// This is what the response object looks like when there are no recent transactions to be found
// It may seem odd to use zod this way, but this lets us be typesafe + check for these specific conditions
const fatalResponseSchema = z.object({
  code: z.literal(13),
  message: z.literal('runtime error: invalid memory address or nil pointer dereference')
})

const partialHostZoneSchema = z.object({
  host_zone: z.object({
    redemptions_enabled: z.boolean()
  })
})

const isAxiosError = (error: any): error is AxiosError => {
  return Boolean(error.isAxiosError)
}

export { useTransactionHistoryQuery }
